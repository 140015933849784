import React, { useState } from 'react';
import styles from './LoginPasswordLost.module.css';
import Input from '../Forms/Input';
import { useUserContext } from '../../UserContextLogin';
import useForm from '../../Hooks/useForm';
import Modal from 'react-modal';
import {
  CODIGO_DESBLOQUEIO,
  ENVIO_SENHA_RECUPERACAO,
  ENVIO_SENHA_RECUPERACAO2,
  POST,
  login_esqueceu_senha,
} from '../../ApiCliente';
import { Navigate, useNavigate } from 'react-router';

const LoginPasswordLost = () => {
  const { error, setError, loading, setLoading } = useUserContext();
  const [verificado, setVerificado] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();
  const cnpj = useForm('cnpj');

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      const response = await POST(login_esqueceu_senha, {
        username: cnpj.value.replace(/\D/g, ''),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      setMensagem(data.message);
      setVerificado(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setVerificado(false);
    navigate('/login');
  };

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={verificado}
        onRequestClose={handleCloseModal}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
      >
        <p>{mensagem}</p>
        <button onClick={handleCloseModal}>Fechar</button>
      </Modal>
      <div className={styles.divForm}>
        <h3>Recuperar Senha</h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label="Insira seu CNPJ"
            type="text"
            name="recuperacao"
            placeholder="00.000.000/0000-00"
            maxLength={18}
            disabled={loading}
            {...cnpj}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>{' '}
          <p className={styles.invalidCnpj}>{error}</p>
        </form>
      </div>
    </section>
  );
};

export default LoginPasswordLost;
