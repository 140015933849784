import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { TOKEN_VALIDATE, USER_LOGIN, VERIFICA_CODIGO_DESBLOQUEIO } from './ApiCliente';
import { useLocation, useNavigate } from 'react-router-dom';



interface UserContextType {
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>
  loading: boolean;
  login: boolean | null;
  data: any;
  token: string | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  userLogout: () => Promise<void>;
  userLogin: (username: string, password: string) => Promise<void>;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserStorage: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState(null);
  const [login, setLogin] = useState<boolean | null>(null);
  const [token, setToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation()


  async function getUser(token: string) {
    try {
      setLoading(true);
      setError(null);
      const { url, options } = TOKEN_VALIDATE(token);
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message)
      setLogin(true);
      setToken(token);
      if (pathname.includes('login')) navigate('/area-cliente/desbloqueio');
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }

  }


  async function userLogin(username: string, password: string) {
    try {
      setLoading(true);
      setError(null);
      const { url, options } = USER_LOGIN({ username, password });
      const tokenRes = await fetch(url, options);
      const json = await tokenRes.json();
      if (!tokenRes.ok) throw new Error(json.message)
      window.localStorage.setItem('token', json.token);
      getUser(json.token);
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false);
    }

  }

  const userLogout = useCallback(async function () {
    setData(null);
    setError(null);
    setLoading(false);
    setLogin(false);
    setToken(null);
    window.localStorage.removeItem('token');
  }, [navigate])

  React.useEffect(() => {
    async function autologin() {
      const token = window.localStorage.getItem('token');
      if (token) {
        try {
          setError(null);
          // setLoading(true);
          const { url, options } = TOKEN_VALIDATE(token);
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Token Inválido');
          await getUser(token);
          setToken(token)
        } catch (err) {
          userLogout();
        } finally {
          setLoading(false);
        }
      }
    }
    autologin();
  }, [userLogout])


  return (
    <UserContext.Provider value={{ setLoading, error, setError, loading, login, data, userLogin, token, userLogout }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserStorage');
  }

  return context;
};

export default UserContext
