import wppVideo from '../../assets/wpp-video.mp4';
import icone from '../../assets/wpp-icon-gg.svg';
import aniversario from '../../assets/wpp-aniversario.svg';
import avulso from '../../assets/wpp-avulso.svg';
import bloqueio from '../../assets/wpp-bloqueio.svg';
import confirma from '../../assets/wpp-confirma.svg';
import pesquisa from '../../assets/wpp-pesquisa.svg';
import recaptacao from '../../assets/wpp-recapta.svg';
import validacao from '../../assets/wpp-valida.svg';

const whatsapp = {
  nome: 'whatsapp',
  span: 'Integração com o Whatsapp',
  titulo: 'Automatize várias tarefas do dia a dia da sua clínica com nossa ',
  desc: 'O KonsistMed revoluciona a comunicação em saúde, integrando o WhatsApp para automatizar confirmações de agendamento, alertas de bloqueio, validação de dados, pesquisas de satisfação, cumprimentos de aniversário e recaptura de pacientes. Eficiência e personalização em um só serviço, simplificando a gestão médica.',
  media: wppVideo,
  icone: icone,
  pontos: [
    {
      titulo: 'Confirmação de agenda',
      desc: 'Envie suas mensagens de confirmação de forma totalmente automática',
      svg: confirma,
    },
    {
      titulo: 'Aniversariantes',
      desc: 'Celebre aniversários dos seus pacientes com felicitações automáticas',
      svg: aniversario,
    },
    {
      titulo: 'Aviso de bloqueio',
      desc: 'Envie aos  seus pacientes alertas imediatos sobre bloqueios na agenda',
      svg: bloqueio,
    },
    // {
    //   titulo: "Mensagem personalizada",
    //   desc: "Envie mensagens únicas e personalizadas sobre campanhas, avisos e etc",
    //   svg: avulso,
    // },
    {
      titulo: 'Pesquisa de satisfação',
      desc: 'Realize pesquisas de satisfação de maneira ágil e automática após o atendimento do paciente',
      svg: pesquisa,
    },
    {
      titulo: 'Recaptação de paciente',
      desc: 'Estimule seus pacientes a retornarem com lembretes disparados em massa',
      svg: recaptacao,
    },
    {
      titulo: 'Validação de dados',
      desc: 'Valide dados de token do convênio, por exemplo, facilmente via WhatsApp',
      svg: validacao,
    },
  ],
};
export default whatsapp;
