import React, { useEffect } from "react";
import AWS from "aws-sdk";
import styles from "./Aplicativos.module.css";

const Aplicativos = () => {
  useEffect(() => {
    // Configurar o cliente AWS S3
    const s3 = new AWS.S3({
      // Configurações da sua conta AWS
      accessKeyId: "AKIA3L4VGFLBOHEXV27H",
      secretAccessKey: "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",
      region: "sa-east-1",
    });

    // URL da página que contém o nome do arquivo
    const pageUrl = window.location.href;
    // Extrair o nome do arquivo da URL
    const fileName = pageUrl.substring(pageUrl.lastIndexOf("/") + 1);

    // Gerar o URL de download do arquivo
    const params = {
      Bucket: "aplicativos-konsist",
      Key: fileName,
    };
    const url = s3.getSignedUrl("getObject", params);

    // Redirecionar para o URL de download
    window.location.href = url;
  }, []);

  return (
    <section className={styles.container}>
      <h1 className={styles.titulo}>
        Download em andamento<span className={styles.ponto}>...</span>
      </h1>
    </section>
  );
};

export default Aplicativos;
