import React from "react";
import styles from "./Titulo.module.css";
import deco from "../../assets/deco-titulo.svg";
import iconeWpp from "../../assets/wpp-icon-gg.svg";
import iconeAgenda from "../../assets/agendaweb-icone.png";
import { useParams } from "react-router-dom";

const Titulo = ({ titulo, subTitulo, decoracao }: any) => {
  const { produto } = useParams();
  return (
    <div
      className={
        produto
          ? `${styles.divTituloProd} animeLeft`
          : `${styles.divTitulo} animeLeft`
      }
    >
      <h2 className={styles.subTitulo}>{subTitulo}</h2>
      {decoracao ? (
        <div className={styles.divH1}>
          <img src={deco} alt="decoração do título" />
          <h1 className={styles.titulo}>
            {titulo}
            <span>.</span>
          </h1>
        </div>
      ) : produto === "whatsapp" || produto === "agendaweb" ? (
        <div className={styles.divH1Wpp}>
          <img
            src={produto === "whatsapp" ? iconeWpp : iconeAgenda}
            alt="logo"
          />
          <h1 className={styles.titulo}>{titulo}</h1>
        </div>
      ) : (
        <h1 className={styles.titulo}>
          {titulo}
          <span>.</span>
        </h1>
      )}
    </div>
  );
};

export default Titulo;
