import React from 'react';
import Head from './Helper/Head';
import Intro from './Home/Intro';
import NossosProdutos from './Home/NossosProdutos';
import Parceiros from './Home/Parceiros';
import Resumo from './Home/Resumo';
import Modal from 'react-modal';
import styles from './Home.module.css';
import { useAvisoContext } from '../UserContext';
import io from 'socket.io-client';
import Parceria from './Home/Parceria';
import Whatsapp from './Helper/Whatsapp';
import WhatsappHome from './Home/WhatsappHome';
import AgendaWebHome from './Home/AgendaWebHome';

// const socket = io('https://konsist.com.br');

const Home = () => {
  const {
    abreAviso,
    setAbreAviso,
    aviso,
    setAviso,
    valorCompartilhado,
    setValorCompartilhado,
  } = useAvisoContext();
  const el = null;
  const dataExpiracao = new Date().getTime() + 60 * 60 * 1000;

  // React.useEffect(() => {
  //   // Manipulador para receber atualizações do servidor WebSocket
  //   socket.on('atualizacao', (data) => {
  //     setValorCompartilhado(data.valor);
  //     if (data.valor.ativo) {
  //       setAbreAviso(true);
  //       setAviso(true);
  //     }
  //   });

  //   // Manipulador para receber o valor inicial do servidor Socket.IO
  //   socket.on('inicializacao', (data) => {
  //     setValorCompartilhado(data.valor);
  //     if (data.valor.ativo) {
  //       setAbreAviso(true);
  //       setAviso(true);
  //     }
  //   });

  //   // Cleanup da conexão ao desmontar o componente
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // React.useEffect(() => {}, [valorCompartilhado]);

  // React.useEffect(() => {
  //   console.log(aviso);
  //   if (!aviso) {
  //     localStorage.setItem('meuItemExpiraEm', dataExpiracao);
  //     localStorage.setItem('carregado', true);
  //   }
  // }, [aviso]);

  // React.useEffect(() => {
  //   const dataExpiracaoLS = localStorage.getItem('meuItemExpiraEm');
  //   if (dataExpiracao && new Date().getTime() > dataExpiracaoLS) {
  //     localStorage.removeItem('carregado');
  //     localStorage.removeItem('meuItemExpiraEm');
  //     if (aviso) setAbreAviso(true);
  //   }
  // }, []);
  return (
    <section className="container mainContainer">
      <Head title="Home" description="Home do site da empresa Konsist" />
      {abreAviso && (
        <Modal
          appElement={el}
          className={styles.modal}
          isOpen={aviso}
          style={{ content: { background: valorCompartilhado.corFundo } }}
          // onRequestClose={() => setAviso(false)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <h2 style={{ color: valorCompartilhado.corTitulo }}>
            {valorCompartilhado.titulo}
          </h2>
          <p style={{ color: valorCompartilhado.corAviso }}>
            {valorCompartilhado.aviso}
          </p>
          <button onClick={() => setAbreAviso(false)}>ciente</button>
        </Modal>
      )}
      <Intro />
      <AgendaWebHome />
      <WhatsappHome />
      <NossosProdutos />
      <Parceria />
      <Parceiros />
      <Resumo />
      {/* <Clientes /> */}
    </section>
  );
};

export default Home;
