import React from "react";
import { Link } from "react-router-dom";
import styles from "./WhatsappHome.module.css";
import imgWpp from "../../assets/wpp.jpg";
import ScrollTrigger from "react-scroll-trigger";

const WhatsappHome = () => {
  const myElementRef = React.useRef(null);

  return (
    <div className={styles.resumo} ref={myElementRef}>
      {myElementRef ? (
        <ScrollTrigger
          onEnter={() => {
            myElementRef.current.classList.add("animeLeft");
          }}
        />
      ) : (
        ""
      )}
      <div className={styles.texto}>
        <h2 className={styles.subTitulo}>AUTOMATIZAÇÃO COM WHATSAPP</h2>
        <h1 className={styles.titulo}>
          novos serviços automáticos com whatsapp<span>.</span>
        </h1>
        <p className={styles.desc}>
          Descubra a revolução dos nossos{" "}
          <span className={styles.spanTexto}>serviços pelo WhatsApp</span>!
          Transforme{" "}
          <span className={styles.spanTexto}>
            a experiência de seus pacientes
          </span>{" "}
          com nossos serviços de{" "}
          <span className={styles.spanTexto}>automação via Whatsapp</span>.
          Agora, oferecemos serviços exclusivos diretamente pelo WhatsApp.{" "}
          <span className={styles.spanTexto}>Confirme agendamentos</span>, envie{" "}
          <span className={styles.spanTexto}>pesquisas de satisfação</span>,
          mensagens para os{" "}
          <span className={styles.spanTexto}>aniversariantes</span> e muito
          mais, tudo de forma automatizada e eficiente. Explore as{" "}
          <span className={styles.spanTexto}>novas possibilidades</span> que o{" "}
          <span className={styles.spanTexto}>KonsistMed oferece!</span>
        </p>
        <div className={styles.divButton}>
          <Link to="/produtos/whatsapp" className={styles.saibaMaisLink}>
            <button className={styles.saibaMais}>SAIBA MAIS</button>
          </Link>
        </div>
      </div>
      <img
        src={imgWpp}
        alt="Imagem da logo do Whatsapp"
        className={styles.imgWpp}
      />
    </div>
  );
};

export default WhatsappHome;
