import React, { useState } from 'react';
import Input from '../Forms/Input';
import styles from './RecuperacaoSenha.module.css';
import { useUserContext } from '../../UserContextLogin';
import { ALTERA_SENHA, POST, RECUPERAR_SENHA } from '../../ApiCliente';
import useForm from '../../Hooks/useForm';
import { Navigate, useNavigate, useParams } from 'react-router';
import Modal from 'react-modal';

const RecuperacaoSenha = () => {
  const { error, setError, loading, setLoading } = useUserContext();
  const [mensagem, setMensagem] = useState(null);
  const [alterado, setAlterado] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const password = useForm('');
  const repassword = useForm('');

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setPasswordError(null);
      if (password.value === repassword.value) {
        if (password.value.length > 5) {
          setLoading(true);
          const { url, options } = ALTERA_SENHA(token, {
            password: password.value,
          });
          const response = await fetch(url, options);
          const { message } = await response.json();
          setMensagem(message);
          if (!response.ok) throw new Error(message);
          setAlterado(true);
        } else {
          setPasswordError('A senha deve possuir no mínimo 6 caracteres');
        }
      } else {
        setPasswordError('As senhas não conferem');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setAlterado(false);
    navigate('/login');
  };

  return (
    <section className={styles.containerMain}>
      <Modal
        className={styles.modal}
        isOpen={alterado}
        onRequestClose={handleCloseModal}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
      >
        <p>{mensagem}</p>
        <button onClick={handleCloseModal}>Fechar</button>
      </Modal>
      <div className={`${styles.divForm} animeLeft`}>
        <h3>Recuperação de senha</h3>
        <form className={`${styles.form} animeLeft`} onSubmit={handleSubmit}>
          <Input
            label="Nova senha"
            type="password"
            name="senha"
            placeholder="Digite a nova senha aqui..."
            classe="animeLeft"
            maxLength={25}
            disabled={loading}
            {...password}
          />
          <div>
            <Input
              label="Confirmar nova senha"
              type="password"
              name="senha2"
              placeholder="Digite novamente a nova senha..."
              classe="animeLeft"
              id=""
              maxLength={25}
              disabled={loading}
              {...repassword}
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
          <p className={styles.error}>{passwordError}</p>
        </form>
      </div>
    </section>
  );
};

export default RecuperacaoSenha;
