import React from "react";
import styles from "./Footer.module.css";
import { ReactComponent as Logofooter } from "../assets/logofooter.svg";
import fone from "../assets/fone.svg";
import whatsapp from "../assets/whatsapp.svg";
import whatsappmini from "../assets/whatsappmini.svg";
import email from "../assets/email.svg";
import instagram from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import endereco from "../assets/endereco.svg";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const [currentRoute, setCurrentRoute] = React.useState("");
  const [setor, setSetor] = React.useState("");
  const location = useLocation();

  React.useEffect(() => {
    setCurrentRoute(location.pathname);
    if (setor === "parceiros" || setor === "clientes") {
      scrollToElement(setor);
    }
  }, [location, setor]);

  function scrollToElement(id: string) {
    if (location.pathname !== "/") setSetor(id);
    else if (location.pathname === "/") {
      setSetor("");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <footer className={styles.footer}>
      <Link
        className={styles.logofooter}
        to="/"
        onClick={() => scrollToElement("header")}
        aria-label="Konsist - Home - Footer"
      >
        <Logofooter />
      </Link>
      <div className={styles.containerFooter}>
        <div className={styles.contatoDiv}>
          <h2 className={styles.informacoes}>CONTATO</h2>
          <div className={styles.dadosContato}>
            <div className={styles.contatoIconsDiv}>
              <img src={fone} className={styles.contatoIcons} id="fone" />
              <img
                src={whatsappmini}
                className={styles.contatoIcons}
                id="whatsapp"
              />
              <img src={email} className={styles.contatoIcons} id="email" />
              <img src={endereco} className={styles.endereco} />
            </div>
            <p className={styles.itemContato}>
              (61)3321-3833 <br />
              (61)98592-7845 (somente Whatsapp) <br /> suporte@konsist.com.br{" "}
              <br /> <br />
              STN Conjunto M Entrada B Sala 213 <br /> Centro Clínico Vital
              Brazil - Asa Norte <br /> Brasília - DF
            </p>
          </div>
          <div className={styles.media}>
            <Link
              className={styles.itemMedia}
              to="https://www.facebook.com/konsistsistemas"
              target={"_blank"}
            >
              <img src={facebook} alt="facebook" />
            </Link>
            <Link
              className={styles.itemMedia}
              to="https://www.instagram.com/konsistsistemas/"
              target={"_blank"}
            >
              <img src={instagram} alt="instagram" />
            </Link>
            <Link
              className={styles.itemMedia}
              to={
                window.innerWidth <= 600
                  ? "https://api.whatsapp.com/send?phone=+5561985927845"
                  : "https://web.whatsapp.com/send?phone=5561985927845&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento%20das%20aplicações%20KonsistMed%2E"
              }
              target={"_blank"}
            >
              <img src={whatsapp} alt="whatsapp" />
            </Link>
          </div>
        </div>
        <div className={styles.infoDiv}>
          <h2 className={styles.informacoes}>INFORMAÇÕES</h2>
          <nav className={styles.nav}>
            <Link
              className={
                currentRoute.includes("/produtos")
                  ? styles.selected
                  : styles.itemInfo
              }
              to="/produtos"
            >
              Produtos
            </Link>
            <Link
              className={
                currentRoute === "/sobre" ? styles.selected : styles.itemInfo
              }
              to="/sobre"
            >
              Sobre
            </Link>
            <Link
              className={
                currentRoute === "/contato" ? styles.selected : styles.itemInfo
              }
              to="/contato"
            >
              Contato
            </Link>
            <Link
              className={styles.itemInfo}
              to="/#parceiros"
              onClick={() => setSetor("parceiros")}
            >
              Parceiros
            </Link>
            <Link
              to="/clientes"
              className={
                currentRoute === "/clientes" ? styles.selected : styles.itemInfo
              }
            >
              Clientes
            </Link>
          </nav>
        </div>
      </div>
      <p className={styles.copyright}>
        Konsist Serviços Empresariais © Todos os direitos reservados.
      </p>
    </footer>
  );
};

export default Footer;
