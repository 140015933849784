import React from 'react'
import { Route, Routes } from 'react-router'
import styles from './AreaCliente.module.css'
import NavAreaCliente from './NavAreaCliente'
import AreaClienteHome from './AreaClienteHome'
import TituloAreaCliente from './TituloAreaCliente'
import Desbloqueio from './Desbloqueio'
import AlterarSenha from './AlterarSenha'
import Pagamentos from './Pagamentos'
import Manuais from './Manuais'
import Links from './Links'

const AreaCliente = () => {
  return (
    <section className={styles.containerMain}>
      <NavAreaCliente />
      <Routes>
        <Route path='/' element={<AreaClienteHome />} />
        <Route path='desbloqueio' element={<Desbloqueio />} />
        <Route path='alterar-senha' element={<AlterarSenha />} />
        <Route path='pagamento' element={<Pagamentos />} />
        <Route path='manuais' element={<Manuais />}></Route>
        <Route path='links' element={<Links />}></Route>
        
      </Routes>
    </section>
  )
}

export default AreaCliente