import React from 'react';
import styles from './OrcamentoForm.module.css';
import InputMask from '../Forms/Input';
import useForm from '../../Hooks/useForm';
import enviarEmail from '../Email/Email';
import Modal from 'react-modal';

const OrcamentoForm = () => {
  const nome = useForm('');
  const telefone = useForm('telefone');
  const email = useForm('email');
  const empresa = useForm('');
  const logradouro = useForm('');
  const bairro = useForm('');
  const cidade = useForm('');
  const [estado, setEstado] = React.useState('');
  const [modulos, setModulos] = React.useState([]);
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const el = null;

  function handleModulos(e) {
    const item = e.target.value;
    const verificaModulos = modulos.includes(item);

    if (verificaModulos && !e.target.checked) {
      const index = modulos.indexOf(item);
      if (index > -1) {
        modulos.splice(index, 1);
      }
    } else if (!verificaModulos && e.target.checked) {
      modulos.push(item);
    }
  }

  async function handleOrcamento(event) {
    event.preventDefault();
    if (
      email.validate() &&
      nome.validate() &&
      telefone.validate() &&
      empresa.validate() &&
      logradouro.validate() &&
      bairro.validate() &&
      cidade.validate() &&
      estado !== null &&
      modulos.length > 0
    ) {
      setLoading(true);
      const mensagem = `<p>Empresa: ${empresa.value}</p><p>Logradouro: ${
        logradouro.value
      }</p><p>Bairro: ${bairro.value}</p><p>Cidade: ${
        cidade.value
      }</p><p>Estado: ${estado}</p><p>Módulos desejados:${modulos.map(
        (modulo) => {
          return ' ' + modulo;
        },
      )}</p>`;

      const enviouProp = await enviarEmail.enviarEmailProposta(
        nome.value,
        email.value,
        modulos,
      );
      const enviouSuporte = await enviarEmail.enviarEmailSuporte(
        mensagem,
        nome.value,
        telefone.value,
        email.value,
        modulos,
      );
      if (enviouSuporte && enviouProp) {
        setEmailSent(true);
        setLoading(false);
      }
    }
  }

  const handleChangeEstado = (e) => {
    setEstado(e.target.value);
  };

  return (
    <>
      <div className={`${styles.orcamento} animeLeft`}>
        <Modal
          appElement={el}
          className={styles.modal}
          isOpen={emailSent}
          onRequestClose={() => setEmailSent(false)}
          ariaHideApp={false}
        >
          <h2>
            Proposta enviada para {email.value}
            <span>!</span>
          </h2>
          <p>
            Agradecemos o interesse em nossos produtos, para dar continuidade na
            contratação do sistema após a leitura da proposta{' '}
            <span>basta nos responder o e-mail</span> da proposta.
          </p>
          <button onClick={() => setEmailSent(false)}>Fechar</button>
        </Modal>
        <form className={styles.form} onSubmit={handleOrcamento}>
          <div className={styles.modulos}>
            <div className={styles.titulo}>
              <span></span>
              <h2>SELECIONE OS MÓDULOS:</h2>
            </div>

            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="Atendimento"
                onChange={(e) => handleModulos(e)}
              />
              Atendimento
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="Faturamento"
                onChange={(e) => handleModulos(e)}
              />
              Faturamento
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="Financeiro"
                onChange={(e) => handleModulos(e)}
              />
              Financeiro
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="Estoque"
                onChange={(e) => handleModulos(e)}
              />
              Estoque
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="NFS-e"
                onChange={(e) => handleModulos(e)}
              />
              NFS-e
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="AWS"
                onChange={(e) => handleModulos(e)}
              />
              Sistema em nuvem*
            </label>
            <label>
              <input
                type="checkbox"
                name="modulos[]"
                value="Nuvem"
                onChange={(e) => handleModulos(e)}
              />
              Integração Whatsapp
            </label>
            <p className={styles.aviso}>
              *O SISTEMA EM NUVEM se trata de uma virtualização de uma área de
              trabalho remota.
            </p>
          </div>
          <div className={styles.orcamentoForm}>
            <InputMask
              placeholder="Seu nome"
              label="Nome"
              type="text"
              name="nome"
              {...nome}
            />
            <InputMask
              placeholder="(61) 99999-9999"
              label="Telefone"
              type="text"
              name="telefone"
              maxLength="15"
              {...telefone}
            />
            <InputMask
              classe="col2"
              placeholder="contato@email.com"
              label="Email"
              type="text"
              name="email"
              {...email}
            />
            <InputMask
              classe="col2"
              placeholder="Nome da sua empresa"
              label="Empresa"
              type="text"
              name="empresa"
              {...empresa}
            />
            <InputMask
              placeholder="Rua, Quadra..."
              label="Logradouro"
              type="text"
              name="logradouro"
              {...logradouro}
            />
            <InputMask label="Bairro" type="text" name="bairro" {...bairro} />
            <InputMask
              placeholder="Digite sua cidade"
              label="Cidade"
              type="text"
              name="cidade"
              {...cidade}
            />
            <div className={styles.estado}>
              <label htmlFor="estados">Estado</label>
              <select
                name="estados"
                id="estados"
                onChange={handleChangeEstado}
                value={estado}
              >
                <option value="Acre">AC</option>
                <option value="Alagoas">AL</option>
                <option value="Amapá">AP</option>
                <option value="Amazonas">AM</option>
                <option value="Bahia">BA</option>
                <option value="Ceará">CE</option>
                <option value="Distrito Federal">DF</option>
                <option value="Espírito Santos">ES</option>
                <option value="Goiás">GO</option>
                <option value="Maranhão">MA</option>
                <option value="Mato Grosso">MT</option>
                <option value="Mato Grosso do Sul">MS</option>
                <option value="Minas Gerais">MG</option>
                <option value="Pará">PA</option>
                <option value="Paraíba">PB</option>
                <option value="Paraná">PR</option>
                <option value="Pernambuco">PE</option>
                <option value="Piauí">PI</option>
                <option value="Rio de Janeiro">RJ</option>
                <option value="Rio Grande do Norte">RN</option>
                <option value="Rio Grande do Sul">RS</option>
                <option value="Rondônia">RO</option>
                <option value="Roraima">RR</option>
                <option value="Santa Catarina">SC</option>
                <option value="São Paulo">SP</option>
                <option value="Sergipe">SE</option>
                <option value="Tocantins">TO</option>
              </select>
            </div>
            {loading ? (
              <button disabled>Enviando...</button>
            ) : (
              <button>Solicitar Orçamento</button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default OrcamentoForm;
