import React from 'react'
import styles from './LoginCliente.module.css'
import { Route, Routes } from 'react-router'
import LoginForm from './LoginForm'
import LoginPasswordLost from './LoginPasswordLost'
import RecuperacaoSenha from './RecuperacaoSenha'

const LoginCliente = () => {
  return (
    <section>
      <Routes>
        <Route path='/' element={<LoginForm />} />
        <Route path='/esqueceu' element={<LoginPasswordLost />} />
        <Route path='/recuperar' element={<RecuperacaoSenha />} />
      </Routes>
    </section>
  )
}

export default LoginCliente