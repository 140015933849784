import React from "react";
import Head from "../Helper/Head";
import Titulo from "../Helper/Titulo";
import Produto from "./Produto";
import styles from "./Produtos.module.css";
import modulos from "./Modulos";

const Produtos = () => {
  return (
    <>
      <Head
        title="Produtos"
        description="Lista de produtos da empresa KonsistMed."
      />
      <Titulo
        subTitulo="ESCOLHA OS MÓDULOS QUE MELHOR LHE ATENDEM"
        titulo="nossas aplicações"
      />
      <section className={`${styles.container} animeLeft`}>
        {modulos.map((produto, index) => (
          <>
            <Produto
              key={index}
              src={produto.media}
              titulo={produto.nome}
              pontos={produto.pontosChave}
              desc={produto.descHome}
              link={produto.nome}
            />
          </>
        ))}
      </section>
    </>
  );
};

export default Produtos;
