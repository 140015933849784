import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Head from "../Helper/Head";
import Titulo from "../Helper/Titulo";
import styles from "./ProdutoPage.module.css";
import setaDir from "../../assets/seta-dir.svg";
import setaEsq from "../../assets/seta-esq.svg";
import modulos from "./Modulos";
import NotFound from "../NotFound";
import whatsapp from "./WppModulo";
import agendaWeb from "./AgendaModulo";
import phone from "../../assets/phone-wpp.png";

const ProdutoPage = () => {
  const location = useLocation();
  const { produto } = useParams();
  const modulo = location.pathname.replace("/produtos/", "").replace(" ", "");
  const moduloFormatado = modulo[0].toUpperCase() + modulo.substring(1);
  const [currentModule, setCurrentModule] = React.useState([]);
  const [posicao, setPosicao] = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const [animeLeft, setAnimeLeft] = React.useState(true);
  const [isPlaying, setIsPlaying] = useState();

  const handleVideoClick = () => {
    const video = document.getElementById("video");

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleLeft = () => {
    if (window.innerWidth <= 600) {
      if (index - posicao > 1) setPosicao(posicao + 1);
    }
    if (index - posicao > 3) setPosicao(posicao + 1);
  };

  const handleRight = () => {
    if (posicao > 0) setPosicao(posicao - 1);
  };

  React.useEffect(() => {
    const currentModuleTrat = modulos.filter((e) => e.nome === modulo);
    setCurrentModule(currentModuleTrat);
    setIndex(modulos.length - 1);
  }, [modulo]);

  React.useEffect(() => {
    const element = document.querySelector(".animeLeft");
    const element2 = document.querySelector(".animeLeftProd");
    if (element && element2) {
      element.classList?.remove("animeLeft");
      element2.classList?.remove("animeLeftProd");
      void element.offsetWidth;
      void element2.offsetWidth; // forçar a atualização da animação
      element.classList?.add("animeLeft");
      element2.classList?.add("animeLeftProd");
    }
  }, [location.pathname]);

  return (
    <>
      {produto === "whatsapp" ? (
        <>
          <Head
            title={moduloFormatado}
            description="Página para contato com a empresa KonsistMed."
          />
          <Titulo
            decoracao={false}
            subTitulo=""
            titulo={`Integração ${moduloFormatado}`}
          />
          <section className={`${styles.containerProdutoWpp} animeLeftProd`}>
            <div className={styles.produto}>
              <div className={styles.descWpp}>
                <h2>
                  {whatsapp.titulo}
                  <span id="spanWpp">{whatsapp.span}</span>
                </h2>
                <p>{whatsapp && whatsapp.desc}</p>
                <Link to="/orcamento" className={styles.buttonWpp}>
                  <button>solicite um orçamento</button>
                </Link>
              </div>
              <div className={styles.divVideo} onClick={handleVideoClick}>
                <video
                  className={styles.videoWpp}
                  src={whatsapp.media}
                  autoPlay
                  muted
                  loop
                  playsInline
                  id="video"
                />
                <div className={styles.imgPhone}></div>
              </div>
            </div>
            <div className={styles.pontosWpp}>
              {whatsapp?.pontos.map((ponto, index) => (
                <div className={styles.pontoWpp} key={index}>
                  <div className={styles.divTituloPonto}>
                    <img
                      src={ponto.svg}
                      alt={`Ícone do ponto "${ponto.titulo}"`}
                    />
                    <p>{ponto.titulo}</p>
                  </div>
                  <p>{ponto.desc}</p>
                </div>
              ))}
            </div>
          </section>
          <section
            className={`${styles.outrosProd} ${animeLeft ? "animeLeft" : ""}`}
          >
            <h1>
              outros produtos<span>.</span>
            </h1>
            <div className={styles.containerOutros}>
              <img
                src={setaEsq}
                alt="seta para rolar os módulos para a direita"
                onClick={handleRight}
                className={styles.setaEsq}
              />
              <div className={styles.produtos}>
                {modulos.map((produto, index) => {
                  if (produto.nome !== modulo) {
                    const nomeFormat =
                      produto.nome[0].toUpperCase() + produto.nome.substring(1);
                    return (
                      <>
                        <Link
                          className={styles.link}
                          to={`/produtos/${produto.nome}`}
                        >
                          <div
                            key={index}
                            className={styles.outroProduto}
                            style={{
                              transform: `translateX(-${posicao * 100}%)`,
                            }}
                          >
                            <img
                              src={produto.img}
                              alt={`imagem do módulo ${produto.nome}`}
                            />
                            <div className={styles.tituloProd}>
                              <span></span>
                              <p>{nomeFormat}</p>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  }
                })}
              </div>
              <img
                src={setaDir}
                alt="seta para rolar os módulos para a direita"
                onClick={handleLeft}
                className={styles.setaDir}
              />
            </div>
          </section>
        </>
      ) : produto === "agendaweb" ? (
        <>
          <Head
            title="Agenda Web"
            description="Página para contato com a empresa KonsistMed."
          />
          <Titulo decoracao={false} subTitulo="" titulo={`Agenda Web`} />
          <section className={`${styles.containerProdutoWpp} animeLeftProd`}>
            <div className={styles.produto}>
              <div className={styles.descWpp}>
                <h2>
                  {agendaWeb.titulo}
                  <span id="spanWpp">{agendaWeb.span}</span>
                </h2>
                <p>{agendaWeb && agendaWeb.desc}</p>
                <Link to="/orcamento" className={styles.buttonWpp}>
                  <button>solicite um orçamento</button>
                </Link>
              </div>
              <div className={styles.divVideo} onClick={handleVideoClick}>
                <video
                  id="video"
                  className={styles.videoWpp}
                  src={agendaWeb.media}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
                <div className={styles.imgPhone}></div>
              </div>
            </div>
            <div className={styles.pontosWpp}>
              {agendaWeb?.pontos.map((ponto, index) => (
                <div className={styles.pontoWpp} key={index}>
                  <div className={styles.divTituloPonto}>
                    <img
                      src={ponto.svg}
                      alt={`Ícone do ponto "${ponto.titulo}"`}
                    />
                    <p>{ponto.titulo}</p>
                  </div>
                  <p>{ponto.desc}</p>
                </div>
              ))}
            </div>
          </section>
          <section
            className={`${styles.outrosProd} ${animeLeft ? "animeLeft" : ""}`}
          >
            <h1>
              outros produtos<span>.</span>
            </h1>
            <div className={styles.containerOutros}>
              <img
                src={setaEsq}
                alt="seta para rolar os módulos para a direita"
                onClick={handleRight}
                className={styles.setaEsq}
              />
              <div className={styles.produtos}>
                {modulos.map((produto, index) => {
                  if (produto.nome !== modulo) {
                    const nomeFormat =
                      produto.nome[0].toUpperCase() + produto.nome.substring(1);
                    return (
                      <>
                        <Link
                          className={styles.link}
                          to={`/produtos/${produto.nome}`}
                        >
                          <div
                            key={index}
                            className={styles.outroProduto}
                            style={{
                              transform: `translateX(-${posicao * 100}%)`,
                            }}
                          >
                            <img
                              src={produto.img}
                              alt={`imagem do módulo ${produto.nome}`}
                            />
                            <div className={styles.tituloProd}>
                              <span></span>
                              <p>{nomeFormat}</p>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  }
                })}
              </div>
              <img
                src={setaDir}
                alt="seta para rolar os módulos para a direita"
                onClick={handleLeft}
                className={styles.setaDir}
              />
            </div>
          </section>
        </>
      ) : modulos.some((modulo) => modulo.nome === produto) ? (
        <>
          {" "}
          <Head
            title={moduloFormatado}
            description="Página para contato com a empresa KonsistMed."
          />
          <Titulo
            decoracao={true}
            subTitulo=""
            titulo={`Módulo ${moduloFormatado}`}
          />
          <section className={`${styles.containerProduto} animeLeftProd`}>
            <div className={styles.produto}>
              <div className={styles.desc}>
                <h2>
                  O que o nosso Módulo de{" "}
                  <span className={styles.spanProd}>{moduloFormatado}</span>{" "}
                  oferece?
                </h2>
                <p>{currentModule && currentModule[0]?.desc}</p>
                <Link to="/orcamento" className={styles.button}>
                  <button>solicite um orçamento</button>
                </Link>
              </div>
              <video
                className={styles.videoProd}
                src={currentModule[0]?.media}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
            <div className={styles.pontos}>
              {currentModule[0]?.pontos.map((ponto, index) => (
                <div key={index} className={styles.ponto}>
                  <img
                    src={ponto.svg}
                    alt={`Ícone do ponto "${ponto.ponto}"`}
                  />
                  <p>{ponto.ponto}</p>
                </div>
              ))}
            </div>
          </section>
          <section
            className={`${styles.outrosProd} ${animeLeft ? "animeLeft" : ""}`}
          >
            <h1>
              outros produtos<span>.</span>
            </h1>
            <div className={styles.containerOutros}>
              <img
                src={setaEsq}
                alt="seta para rolar os módulos para a direita"
                onClick={handleRight}
                className={styles.setaEsq}
              />
              <div className={styles.produtos}>
                {modulos.map((produto, index) => {
                  if (produto.nome !== modulo) {
                    const nomeFormat =
                      produto.nome[0].toUpperCase() + produto.nome.substring(1);
                    return (
                      <>
                        <Link
                          className={styles.link}
                          to={`/produtos/${produto.nome}`}
                        >
                          <div
                            key={index}
                            className={styles.outroProduto}
                            style={{
                              transform: `translateX(-${posicao * 100}%)`,
                            }}
                          >
                            <img
                              src={produto.img}
                              alt={`imagem do módulo ${produto.nome}`}
                            />
                            <div className={styles.tituloProd}>
                              <span></span>
                              <p>{nomeFormat}</p>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  }
                })}
              </div>
              <img
                src={setaDir}
                alt="seta para rolar os módulos para a direita"
                onClick={handleLeft}
                className={styles.setaDir}
              />
            </div>
          </section>{" "}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ProdutoPage;
