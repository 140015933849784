import React, { useEffect } from "react";
import Head from "../Helper/Head";
import Titulo from "../Helper/Titulo";
import FormContato from "./FormContato";
import styles from "./Contato.module.css";
import InfoContato from "./InfoContato";
import ScrollTrigger from "react-scroll-trigger";
import Local from "./Local";

const Contato = () => {
  const myElementRef = React.useRef(null);

  return (
    <>
      <Head
        title="Contato"
        description="Página para contato com a empresa KonsistMed."
      />
      <Titulo
        subTitulo="fale conosco em um de nossos canais de atendimento"
        titulo="nosso contato"
      />
      <section className={`${styles.contato} animeLeft`}>
        <InfoContato />
        <FormContato />
      </section>
      <Local />
    </>
  );
};

export default Contato;
