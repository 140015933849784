import React, { Children, useState } from "react";
import styles from "./Stories.module.css";
import Timeout from "./Timeout";
import { Link } from "react-router-dom";
import modulos from "../Produtos/Modulos";

const Stories = () => {
  const [currentStory, setCurrentStory] = useState(0);
  const [currentThumb, setCurrentThumb] = useState(0);
  const [video, setVideo] = useState({});
  let videoRef = React.useRef(null);
  let thumbItem = React.useRef(null);
  const [duration, setDuration] = useState("");
  const [idTimeout, setIdTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paused, setPaused] = useState(false);
  const [pausedTimeout, setPausedTimeout] = useState(null);

  const handleNextStory = () => {
    if (currentStory === modulos.length - 1) {
      setCurrentStory(0);
      video.currentTime = 0;
    } else {
      setCurrentStory(currentStory + 1);
      video.currentTime = 0;
    }
  };

  const handleBackStory = () => {
    if (currentStory === 0) {
      setCurrentStory(modulos.length - 1);
      video.currentTime = 0;
    } else {
      setCurrentStory(currentStory - 1);
      video.currentTime = 0;
    }
  };

  const pause = () => {
    document.body.classList.add("paused");
    setPausedTimeout(
      (pausedTimeout = new Timeout(() => {
        idTimeout?.pause();
        setPaused(true);
        video.pause();
      }, 200))
    );
  };

  const continueVideo = () => {
    document.body.classList.remove("paused");
    pausedTimeout?.clear();
    idTimeout.clear();
    if (paused) {
      paused = false;
      idTimeout?.continue();
      thumbItem?.current.classList.remove("paused");
      video.play();
    }
  };

  function handleLoadMetadata() {
    setVideo(videoRef.current);
    setDuration(videoRef.current.duration);
    setIsLoading(false);
  }

  React.useEffect(() => {
    clearTimeout(idTimeout);
    autoStory();
  }, [duration, currentStory]);

  // React.useEffect(() => {
  //   handleLoadMetadata();
  // }, []);

  function autoStory() {
    if (!isLoading) {
      let newIdTimeout = setTimeout(() => {
        handleNextStory();
        setCurrentThumb(currentStory);
      }, duration * 1000);
      setIdTimeout(newIdTimeout);
    }
  }

  return (
    <>
      <div
        className={
          isLoading ? `${styles.stories} ${styles.loading}` : styles.stories
        }
      >
        <div className={styles.storyContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            ref={videoRef}
            type="video/mp4"
            src={modulos[currentStory].media}
            className={styles.video}
            onLoadedMetadata={handleLoadMetadata}
          />
        </div>
        <div className={`${styles.navButtons}`} /* onPointerDown={pause} */>
          <div className={`${styles.slideThumb}`}>
            {modulos.map((el, index) => (
              <span key={index}>
                <span
                  ref={thumbItem}
                  key={currentStory}
                  className={`${styles.thumbItem} ${
                    index === currentStory ? `${styles.active}` : ""
                  }`}
                  style={{ animationDuration: `${duration}s` }}
                ></span>
              </span>
            ))}
          </div>
          <button onPointerUp={handleBackStory}>Anterior</button>
          <button onPointerUp={handleNextStory}>Próximo</button>
        </div>
      </div>
      <div className={styles.modulo}>
        {isLoading ? (
          <div></div>
        ) : (
          <>
            <div className={styles.tituloMarc}>
              <span className={styles.marcHorz}></span>
              <h1 className={styles.titulo}>{`Módulo ${
                modulos[currentStory].nome[0].toUpperCase() +
                modulos[currentStory].nome.substring(1)
              }`}</h1>
            </div>
            <p className={styles.desc}>{modulos[currentStory].descHome}</p>
            <div className={styles.pontos}>
              {modulos[currentStory].pontosChave.map((ponto, index) => (
                <div key={index} className={styles.marcador}>
                  <span className={styles.marcVert}></span>
                  <p className={styles.ponto}>{ponto}</p>
                </div>
              ))}
            </div>
            <Link
              to={`/produtos/${modulos[currentStory].nome}`}
              className={styles.saibaMaisLink}
            >
              <button className={styles.saibaMais}>SAIBA MAIS</button>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Stories;
