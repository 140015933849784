import React from 'react';
import Head from '../Helper/Head';
import Titulo from '../Helper/Titulo';
import styles from './Suporte.module.css';
import InputMask from '../Forms/Input';
import useForm from '../../Hooks/useForm';

const Suporte = () => {
  const cliente = useForm('');
  const [modulos, setModulos] = React.useState('');
  const [exe, setExe] = React.useState('');

  function handleModulos(e) {
    setModulos(e.target.value);
  }

  function handleExe(e) {
    setExe(e.target.value);
  }
  function handleDownload(e) {
    e.preventDefault();
    const baixar = document.createElement('a');
    if (
      (modulos === 'atendimento' || modulos === 'faturamento') &&
      cliente.validate()
    ) {
      const url = `https://konsist.com.br/aplicativos/p${modulos}${cliente.value}.${exe}`;
      baixar.href = url;
    }
    if (
      modulos === 'financeiro' ||
      modulos === 'estoque' ||
      modulos === 'utilitario'
    ) {
      const url = `https://konsist.com.br/aplicativos/p${modulos}.exe1`;
      baixar.href = url;
    }
    if (modulos === 'nfse') {
      const url = `https://konsist.com.br/aplicativos/pkonsist${modulos}.exe1`;
      baixar.href = url;
    }
    baixar.target = '_blank';
    baixar.click();
  }

  React.useEffect(() => {
    console.log(modulos);
  }, [modulos]);

  return (
    <>
      <Head title="Suporte" description="Um pouco da história da KonsistMed." />
      <Titulo subTitulo="links úteis para" titulo="o suporte" />
      <section className={`${styles.suporte} animeLeft`}>
        <div className={styles.item}>
          <div className={styles.titulo}>
            <span className={styles.span0}></span>
            <h2>Download de Aplicativo</h2>
          </div>
          <form className={styles.download} onSubmit={handleDownload}>
            <InputMask
              placeholder="Nome do cliente"
              label="Cliente"
              type="text"
              name="modulo"
              {...cliente}
            />

            <h3>Selecione o módulo:</h3>
            <div className={styles.modulos}>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="atendimento"
                  onChange={(e) => handleModulos(e)}
                />
                Atendimento
              </label>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="faturamento"
                  onChange={(e) => handleModulos(e)}
                />
                Faturamento
              </label>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="financeiro"
                  onChange={(e) => handleModulos(e)}
                />
                Financeiro
              </label>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="estoque"
                  onChange={(e) => handleModulos(e)}
                />
                Estoque
              </label>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="nfse"
                  onChange={(e) => handleModulos(e)}
                />
                NFS-e
              </label>
              <label>
                <input
                  type="radio"
                  name="modulos[]"
                  value="utilitario"
                  onChange={(e) => handleModulos(e)}
                />
                Utilitário
              </label>
            </div>

            <h3>Tipo de Cliente:</h3>
            <div className={styles.exe}>
              <label>
                <input
                  type="radio"
                  name="exe"
                  value="exe"
                  onChange={(e) => handleExe(e)}
                />
                Cliente novo
              </label>
              <label>
                <input
                  type="radio"
                  name="exe"
                  value="exe1"
                  onChange={(e) => handleExe(e)}
                />
                Já é cliente
              </label>
            </div>

            <button>BAIXAR</button>
          </form>
        </div>
        <div className={styles.item}>
          <div className={styles.titulo}>
            <span className={styles.span1}></span>
            <h2>Banco de dados</h2>
          </div>
          <div className={styles.links}>
            <a
              target="_blank"
              href="https://www.enterprisedb.com/downloads/postgres-postgresql-downloads"
            >
              <button>PostgreSQL 13</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/postgresql-8.4.18-1-windows.exe">
              <button>PostgreSQL 8.4</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/novopadrao.backup">
              <button>Backup padrão 13</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/novopadrao84.backup">
              <button>Backup padrão 8.4</button>
            </a>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.titulo}>
            <span className={styles.span2}></span>
            <h2>Instaladores KonsistMed</h2>
          </div>
          <div className={styles.links2}>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/SetupKonsistMed13.exe">
              <button>KonsistMed Versão 13</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/NovoSetupKonsistMed84.exe">
              <button>KonsistMed Versão 8.4</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/setupkonsistmednfse.exe">
              <button>Instalador NFS-e</button>
            </a>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.titulo}>
            <span className={styles.span3}></span>
            <h2>Links Variados</h2>
          </div>
          <div className={styles.links3}>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/psqlodbc.msi">
              <button>Instalador ODBC 8.4</button>
            </a>
            <a href="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/SetupKonsistLeitorBiometrico.exe">
              <button>Leitor Biométrico</button>
            </a>
            <a href="https://aka.ms/vs/17/release/vc_redist.x64.exe">
              <button>Visual C++ x64</button>
            </a>
            <a href="https://aka.ms/vs/17/release/vc_redist.x86.exe">
              <button>Visual C++ x86</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Suporte;
