import React from 'react';
import useForm from '../../Hooks/useForm.js';
import InputMask from '../Forms/Input.js';
import styles from './FormContato.module.css';
import enviarEmail from '../Email/Email';
import Modal from 'react-modal';

const FormContato = () => {
  const nome = useForm('');
  const telefone = useForm('telefone');
  const email = useForm('email');
  const [mensagem, setMensagem] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function handleMessage(event) {
    setMensagem(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (email.validate() && nome.validate() && telefone.validate()) {
      setLoading(true);
      const enviou = await enviarEmail.enviarEmailSuporte(
        `<br/><br/><p>${mensagem}</p>`,
        nome.value,
        telefone.value,
        email.value,
        '',
      );
      if (enviou) {
        setLoading(false);
        setEmailSent(true);
      }
    }
  }

  return (
    <div className={styles.contatoForm}>
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={() => setEmailSent(false)}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={false}
      >
        <h2>
          Recebemos sua mensagem<span>!</span>
        </h2>
        <p>
          Entratemos em contato com você <span>em breve.</span>
        </p>
        <button onClick={() => setEmailSent(false)}>Fechar</button>
      </Modal>
      <form className={styles.form} onSubmit={handleSubmit}>
        <InputMask
          placeholder="Seu nome"
          label="Nome"
          type="text"
          name="nome"
          {...nome}
        />
        <InputMask
          placeholder="(61) 99999-9999"
          label="Telefone"
          type="text"
          name="telefone"
          maxLength="15"
          {...telefone}
        />
        <InputMask
          classe="col2"
          placeholder="contato@email.com"
          label="Email"
          type="mail"
          name="email"
          {...email}
        />
        <div className={`${styles.mensagem} col2`}>
          <label htmlFor="mensagem">Mensagem</label>
          <textarea
            id="mensagem"
            placeholder="Em que podemos ajudar?"
            onChange={handleMessage}
            value={mensagem}
          />
        </div>
        {loading ? (
          <button className={styles.button} disabled>
            ENVIANDO...
          </button>
        ) : (
          <button className={styles.button}>ENVIAR MENSAGEM</button>
        )}
      </form>
    </div>
  );
};

export default FormContato;
