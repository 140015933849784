import React from "react";
import Head from "../Helper/Head";
import Titulo from "../Helper/Titulo";
import { Link } from "react-router-dom";
import styles from "./Certificado.module.css";
import imgCert from "../../assets/img-cert.svg";
import logoParc from "../../assets/logo-parc.svg";

const Certificado = () => {
  return (
    <>
      <Head title="Certificado Digital" description="Adquira seu certificado" />
      <Titulo
        subTitulo="ADQUIRA SEU CERTIFICADO DIGITAL CONOSCO"
        titulo="com o melhor preço"
      ></Titulo>
      <section className={`${styles.cert} animeLeft`}>
        <div className={styles.infoCert}>
          <h1 className={styles.tituloCert}>
            Seu certificado digital facilitado
            <span className={styles.ponto}>.</span>
          </h1>
          <p className={styles.descCert}>
            Adquira seu{" "}
            <span className={styles.destaqueCert}>certificado digital</span> com
            segurança e agilidade, sem sair de casa, com a mais nova parceria
            entre{" "}
            <span className={styles.destaqueCert}>
              Konsist + Tecnosign + Soluti.
            </span>
          </p>
          <Link to="https://service.certcontrol.com.br/venda-rapida/vd0tp6210tkbee4bfe4a1">
            <button className={styles.orcButton}>COMPRAR CERTIFICADO</button>
          </Link>
        </div>
        <div className={styles.imgDiv}>
          <img
            className={styles.imgCert}
            src={imgCert}
            alt="Imagem de uma mulher utilizando um tablet"
          />
          <img
            className={styles.logoParc}
            src={logoParc}
            alt="Logos das empresas incluídas na parceria"
          />
        </div>
      </section>
    </>
  );
};

export default Certificado;
