import axios from "axios";
import { API_KEY } from "../../../src/config.js";

const apiKey = atob(API_KEY);
const enviarEmailSuporte = async (mensagem, nome, telefone, email, modulos) => {
  let sentEmail = false;
  await axios
    .post(
      "https://api.sendinblue.com/v3/smtp/email",
      {
        sender: { name: "KonsistMed", email: "financeiro@konsist.com.br" },
        to: [
          { email: "suporte@konsist.com.br" },
          { email: "konsist@konsist.com.br" },
          { email: "financeiro@konsist.com.br" },
        ],
        subject: `${
          modulos instanceof Array
            ? "Proposta enviada para: " + nome
            : "Fale Conosco: " + nome
        }`,
        htmlContent: `<html><body><div><p>Nome: ${nome}</p><p>Telefone: ${telefone}</p><p>Email: ${email}</p><p>${mensagem}</p></div></body></html>`,
      },
      {
        headers: {
          "api-key": apiKey,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("E-mail enviado com sucesso!", response.status);
      if (response.status === 201) {
        sentEmail = true;
      }
    })
    .catch((error) => {
      console.error("Erro ao enviar e-mail", error);
    });
  return sentEmail;
};

const enviarEmailProposta = async (nome, email, modulos) => {
  let headersList = {
    "api-key": apiKey,
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    sender: {
      name: "Konsist",
      email: "financeiro@konsist.com.br",
    },
    to: [
      {
        email: email,
        name: nome,
      },
    ],
    templateId: 1,
    params: {
      NOME: nome,
    },
  });

  let response = await fetch("https://api.sendinblue.com/v3/smtp/email", {
    method: "POST",
    body: bodyContent,
    headers: headersList,
  });
  if (response.status === 201) return true;
  else return response;
};

export default { enviarEmailSuporte, enviarEmailProposta };
