import React from 'react'
import styles from "./ServicosOnline.module.css";
const ServicosOnline = () => {
  React.useEffect(() =>{
    window.location.href = 'http://ec2-18-231-58-195.sa-east-1.compute.amazonaws.com:4001/servicosonline/'
  }, [])
  return (
    <div className={`${styles.mainContainer} ${styles.container}`}>
      <h1 className={styles.title}>Redirecionando...</h1>
      <p>Aguarde um instante, você será redirecionando para a página de serviços online.</p>
    </div>
  )
}

export default ServicosOnline