import React from "react";
import Head from "../Helper/Head";
import Titulo from "../Helper/Titulo";
import styles from "./Orcamento.module.css";
import OrcamentoForm from "./OrcamentoForm.js";

const Orcamento = () => {
  return (
    <>
      <Head
        title="Orçamento"
        description="Lista de produtos da empresa Konsist"
      />
      <Titulo
        subTitulo="receba uma proposta por email"
        titulo="solicite um orçamento"
      />
      <section className={styles.mainContainer}>
        <OrcamentoForm />
      </section>
    </>
  );
};

export default Orcamento;
