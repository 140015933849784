import React, { Children } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../../UserContextLogin';

const ProtectedRoute = ({ children }) => {
  const { login } = useUserContext(useUserContext);

  if (login === true) return children;
  else if (login === false) return <Navigate to="/login" />;
  else return <></>;
};

export default ProtectedRoute;
