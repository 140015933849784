import React, { useState, useEffect } from 'react';
import styles from './Pagamentos.module.css';
import { useUserContext } from '../../UserContextLogin';
import { PEGAR_BOLETO } from '../../ApiCliente';
import { Link } from 'react-router-dom';

const Pagamentos = () => {
  const { error, setError, loading, setLoading, token } = useUserContext();
  const [boletos, setBoletos] = useState([]);

  useEffect(() => {
    async function fetchBoletos() {
      try {
        setError(null);
        setLoading(true);
        const { url, options } = PEGAR_BOLETO(token);
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error('Erro ao consultar boletos');
        setBoletos(json);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchBoletos();
  }, [token]);

  return (
    <section className={styles.containerMain}>
      <div className={`${styles.divForm} animeLeft`}>
        <h3>Faturas em aberto</h3>
        {loading && <p className={styles.carregando}>Carregando...</p>}
        {/* {error && <p>{error}</p>} */}
        {boletos.length > 0 && (
          <div className={styles.divTable}>
            <table className={styles.table}>
              <tr>
                <th>Vencimento</th>
                <th>Valor Original</th>
              </tr>
              <span className={styles.sep}></span>
              {boletos.map((boleto) => (
                <Link
                  to={boleto.url}
                  target="_blank"
                  className={styles.linkBoleto}
                >
                  <tr key={boleto.id_integracao} className={styles.tr}>
                    <td className={styles.dados}>{boleto.data_vencimento}</td>
                    <td className={styles.dados}>{boleto.valor}</td>
                  </tr>
                </Link>
              ))}
            </table>
            <p className={styles.info}>
              Para emitir o boleto clique na data referente acima
            </p>
          </div>
        )}
        {boletos.length === 0 && !loading && (
          <p className={styles.menssagem}>Não existem faturas em aberto.</p>
        )}
      </div>
    </section>
  );
};

export default Pagamentos;
