import agendaVideo from '../../assets/agenda_web_video.mp4';
import icone from '../../assets/wpp-icon-gg.svg';
import phone from '../../assets/phone.svg';
import calendar from '../../assets/calendar.svg';
import evolucao from '../../assets/evolucao.svg';
import confirma from '../../assets/wpp-confirma.svg';
import pesquisa from '../../assets/wpp-pesquisa.svg';
import recaptacao from '../../assets/wpp-recapta.svg';
import validacao from '../../assets/wpp-valida.svg';

const agendaWeb = {
  nome: 'agenda web',
  span: 'Agenda Web',
  titulo: 'Transforme a praticidade em sua rotina médica com nossa ',
  desc: 'Acesse e atualize sua agenda em qualquer lugar, a qualquer hora, com nossa Agenda Web, levando o cuidado com seus pacientes para além do desktop.',
  media: agendaVideo,
  icone: icone,
  pontos: [
    {
      titulo: 'Visualizar Agenda',
      desc: 'Verifique seus pacientes agendados de onde estiver com agilidade',
      svg: calendar,
    },
    {
      titulo: 'Marcar Atendido',
      desc: 'Após o tele-atendimento do paciente, marque-o como atendido pela Agenda Web',
      svg: confirma,
    },
    {
      titulo: 'Evolua o Prontuário',
      desc: 'Preencha uma nova evolução do atendimento paciente de onde estiver',
      svg: evolucao,
    },
    {
      titulo: 'Acesso pelo celular',
      desc: 'A Agenda Web é totalmente responsiva, acessada pelo celular, tablet ou computador',
      svg: phone,
    },
  ],
};
export default agendaWeb;
