import React from "react";
import styles from "./Pesquisa.module.css";
import Titulo from "../Helper/Titulo";

const Pesquisa = () => {
  return (
    <>
      <Titulo
        subTitulo="SUA OPINIÃO É MUITO IMPORTANTE PARA NÓS"
        titulo="pesquisa de satisfação"
      />
      <div className={styles.containerIframe}>
        <iframe
          className={styles.iframe}
          src="https://forms.gle/CsgpoSSnEZ3v8k7r7"
        ></iframe>
      </div>
    </>
  );
};

export default Pesquisa;
