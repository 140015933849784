import React from "react";
import styles from "./Parceria.module.css";
import ScrollTrigger from "react-scroll-trigger";
import imgParceria from "../../assets/img-parceria.jpg";
import { Link } from "react-router-dom";

const Parceria = () => {
  const myElementRef = React.useRef(null);

  return (
    <div className={styles.resumo} ref={myElementRef}>
      {myElementRef ? (
        <ScrollTrigger
          onEnter={() => {
            myElementRef.current.classList.add("animeLeft");
          }}
        />
      ) : (
        ""
      )}
      <div className={styles.texto}>
        <h2 className={styles.subTitulo}>LANÇAMENTO EXCLUSIVO</h2>
        <h1 className={styles.titulo}>
          certificados digitais para clínicas e consultórios<span>.</span>
        </h1>
        <p className={styles.desc}>
          Prezados clientes e médicos parceiros, temos uma grande novidade para
          compartilhar com vocês! Agora, como parte de nossa constante busca por
          inovação e soluções digitais, estamos orgulhosos de anunciar que nos
          tornamos parceiros oficiais junto à TecnoSign e Soluti para emissão de
          Certificação Digital para Pessoa Jurídica e Pessoa Física.
        </p>
        <Link to="/certificado" className={styles.link}>
          MAIS INFORMAÇÕES <span className={styles.sub}></span>
        </Link>
      </div>
      <img
        src={imgParceria}
        alt="Imagem de uma pessoa usando um notebook com uma frase sobre o certifidado digital"
        className={styles.imgParceria}
      />
    </div>
  );
};

export default Parceria;
