export const API_URL = 'https://api-areacliente.vercel.app';
export const login_esqueceu_senha = '/login/esqueceu-senha';
export const RECUPERAR_SENHA = '/login/recuperar-senha';
const headers = {
  'Content-Type': 'application/json',
};

export function USER_LOGIN(body) {
  return {
    url: API_URL + '/login/entrar',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}
export function TOKEN_VALIDATE(token) {
  return {
    url: API_URL + '/login/token-validate',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    },
  };
}
export function VERIFICA_CODIGO_DESBLOQUEIO(token) {
  return {
    url: API_URL + '/desbloqueio/verifica',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    },
  };
}

export function CODIGO_DESBLOQUEIO(token, data) {
  return {
    url: API_URL + '/desbloqueio',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  };
}

export function ENVIO_SENHA_RECUPERACAO(data) {
  return {
    url: API_URL + '/login/esqueceu-senha',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  };
}

export async function ENVIO_SENHA_RECUPERACAO2(data) {
  const url = API_URL + '/login/esqueceu-senha';
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };
  const response = await fetch(url, options);
  return response;
}

export async function POST(url, data) {
  return await fetch(API_URL + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
}

export async function POST_TOKEN(url, token, data) {
  return await fetch(API_URL + url, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function GET(url) {
  return await fetch(API_URL + url, { method: 'GET' }), headers;
}

export function ALTERA_SENHA(token, data) {
  return {
    url: API_URL + '/login/recuperar-senha',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  };
}

export function PEGAR_BOLETO(token) {
  return {
    url: API_URL + '/boletos',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    },
  };
}
