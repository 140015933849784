import React from "react";
import styles from "./InfoContato.module.css";
import { ReactComponent as Fone } from "../../assets/fone.svg";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { ReactComponent as Whatsappmini } from "../../assets/whatsappmini.svg";
import { ReactComponent as Email } from "../../assets/email.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Endereco } from "../../assets/endereco.svg";
import { Link, useLocation } from "react-router-dom";

const InfoContato = () => {
  return (
    <div className={styles.contatoDivC}>
      <h2 className={styles.informacoes}>Contato</h2>
      <div className={styles.dadosContato}>
        <div className={styles.contatoIconsDivC}>
          <Fone className={styles.contatoIconsC} id="fone" />
          <Whatsappmini className={styles.contatoIconsC} id="whatsapp" />
          <Email className={styles.emailC} id="email" />
          <Endereco className={styles.enderecoC} />
        </div>
        <div className={styles.texto}>
          <p className={styles.itemContato}>
            (61)3321-3833 <br />
            (61)98592-7845 (somente Whatsapp) <br /> <br />{" "}
            suporte@konsist.com.br
          </p>
          <p className={styles.local}>
            STN Conjunto M Entrada B Sala 213 <br /> Centro Clínico Vital Brazil
            - Asa Norte <br /> Brasília - DF
          </p>
        </div>
      </div>
      <div className={styles.mediaC}>
        <Link
          className={styles.itemMediaC}
          to="https://www.facebook.com/konsistsistemas"
          target={"_blank"}
        >
          <Facebook />
        </Link>
        <Link
          className={styles.itemMediaC}
          to="https://www.instagram.com/konsistsistemas/"
          target={"_blank"}
        >
          <Instagram />
        </Link>
        <Link
          className={styles.itemMediaC}
          to="https://web.whatsapp.com/send?phone=5561985927845&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento%20das%20aplicações%20KonsistMed%2E"
          target={"_blank"}
        >
          <Whatsapp />
        </Link>
      </div>
    </div>
  );
};

export default InfoContato;
