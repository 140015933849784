import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import Produtos from "./Produtos";
import ProdutoPage from "./ProdutoPage";



const TodosProdutos = () => {
  return (
    <section>
      <div>
        <Routes>
          <Route path="/" element={<Produtos />} />
          <Route path="/:produto" element={<ProdutoPage />} />
        </Routes>
      </div>
    </section>
  );
};

export default TodosProdutos;
