import React from 'react';
import styles from './Input.module.css';

const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  onBlur,
  placeholder,
  classe,
  maxLength,
  disabled,
  ...props
}) => {
  return (
    <div className={`${styles.wrapper} ${classe}`}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        ref={props.refInput}
        placeholder={placeholder}
        id={props.id}
        name={name}
        className={`${styles.input} ${props.readOnly ? styles.senhaDes : ''}`}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        readOnly={props.readOnly}
        disabled={disabled}
        onClick={props.onClick}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default Input;
