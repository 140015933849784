import React, { useEffect, useRef, useState } from 'react';
import styles from './Desbloqueio.module.css';
import Input from '../Forms/Input';
import useForm from '../../Hooks/useForm';
import {
  CODIGO_DESBLOQUEIO,
  VERIFICA_CODIGO_DESBLOQUEIO,
} from '../../ApiCliente';
import { useUserContext } from '../../UserContextLogin';

const Desbloqueio = () => {
  const inputRef = useRef(null);
  const { error, setError, loading, setLoading, token } = useUserContext();
  const [verificado, setVerificado] = useState(null);
  const codigo = useForm('codDes');
  const [mensagem, setMensagem] = useState(null);
  const [desbloqueado, setDesbloqueado] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [senha, setSenha] = useState('');
  const [exibirMensagem, setExibirMensagem] = useState(false);

  async function verificaDesbloqueio() {
    try {
      setError(null);
      setLoading(true);
      const { url, options } = VERIFICA_CODIGO_DESBLOQUEIO(token);
      const responseCheck = await fetch(url, options);
      const { message } = await responseCheck.json();
      setMensagem(message);
      if (!responseCheck.ok) throw new Error(message);
      setVerificado(true);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (codigo.validate()) {
      try {
        setError(null);
        setLoading(true);
        const { url, options } = CODIGO_DESBLOQUEIO(token, {
          codigo: codigo.value.replace(/\D/g, ''),
        });
        const responseCodigo = await fetch(url, options);
        const json = await responseCodigo.json();
        setMensagem(json.message);
        if (!responseCodigo.ok) throw new Error(json.message);
        setDesbloqueado(true);
        setSenha(json.senha);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    verificaDesbloqueio();
  }, []);

  function handleChangeSenha(e) {
    setSenha(e.target.value);
  }

  const handleCopy = () => {
    const input = inputRef.current;
    input.select(); // Seleciona o texto no input

    try {
      navigator.clipboard.writeText(input.value);
      setExibirMensagem(true);
    } catch (err) {
      console.error('Falha ao copiar: ', err);
      alert('Falha ao copiar o conteúdo.');
    }

    input.setSelectionRange(0, 0); // Desseleciona o texto
  };

  return (
    <section className={styles.containerMain}>
      <div className={`${styles.divForm} animeLeft`}>
        <h3>Desbloqueio de sistema</h3>
        {verificado ? (
          <form className={`${styles.form} animeLeft`} onSubmit={handleSubmit}>
            <Input
              label="Código de desbloqueio"
              type="text"
              name="codigodesbloqueio"
              placeholder="Digite ou cole o código aqui..."
              classe=""
              maxLength={23}
              disabled={false}
              {...codigo}
            />
            <button type="submit">desbloquear</button>
            {desbloqueado && (
              <div>
                <Input
                  refInput={inputRef}
                  label="Senha de desbloqueio"
                  type="text"
                  name="senha"
                  placeholder="00000-00000-00000-00000-00000"
                  classe="animeLeft"
                  id="copiar"
                  maxLength={29}
                  disabled={false}
                  readOnly={true}
                  onChange={handleChangeSenha}
                  value={senha.replace(
                    /(\d{5})(\d{5})(\d{5})(\d{5})(\d{5})/,
                    '$1-$2-$3-$4-$5',
                  )}
                  onClick={handleCopy}
                />
                {exibirMensagem && (
                  <p className={styles.msgCopiada}>
                    Senha copiada para a área de tranferência.
                  </p>
                )}
              </div>
            )}
          </form>
        ) : (
          <p className={styles.desInvalido}>{mensagem}</p>
        )}
      </div>
    </section>
  );
};

export default Desbloqueio;
