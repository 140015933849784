import React, { useEffect, useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import styles from './Parceiros.module.css';
import orizon from '../../assets/orizon.png';
import hasest from '../../assets/hasest.png';
import tuotempo from '../../assets/tuotempo.png';
import saitec from '../../assets/saitec.png';
import skyone from '../../assets/skyone.png';
import seat from '../../assets/seat.png';
import nina from '../../assets/nina.svg';
import aws from '../../assets/aws.svg';
import exph from '../../assets/exph.png';
import qualisystem from '../../assets/qualisystem.png';
import futurotec from '../../assets/futurotec.png';
import clever from '../../assets/clever.png';
import viapc from '../../assets/viapc.png';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { execPath } from 'process';

const Parceiros = () => {
  const myElementRef = React.useRef(null);
  const myElementRef2 = React.useRef(null);
  const card = React.useRef(null);
  const [width, setWidth] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  const parceiros = [
    {
      img: orizon,
      url: 'https://www.orizonbrasil.com.br/',
    },
    {
      img: hasest,
      url: 'http://www.hasest.com.br/',
    },
    {
      img: aws,
      url: 'https://aws.amazon.com/pt/',
    },
    {
      img: saitec,
      url: 'https://saitec.com.br/',
    },
    {
      img: tuotempo,
      url: 'https://www.tuotempo.com.br/',
    },
    {
      img: skyone,
      url: 'https://skyone.solutions/',
    },
    {
      img: seat,
      url: 'https://www.seat.ind.br/',
    },
    {
      img: nina,
      url: 'https://www.ninatecnologia.com.br/',
    },
    {
      img: exph,
      url: 'https://exph.com.br/',
    },
    {
      img: clever,
      url: 'https://cleversystems.com.br/',
    },
    {
      img: qualisystem,
      url: 'https://www.qualisystem.com.br/',
    },
    {
      img: futurotec,
      url: 'https://futurotec.com.br/',
    },
    {
      img: viapc,
      url: 'https://www.viapc.com.br/',
    },
  ];

  useEffect(() => {
    setWidth(
      myElementRef.current?.scrollWidth - myElementRef.current?.offsetWidth,
    );
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.innerWidth <= 600) {
        setCurrentIndex((prevIndex) =>
          prevIndex >= parceiros.length - 2 ? 0 : prevIndex + 1,
        );
      } else {
        setCurrentIndex((prevIndex) =>
          prevIndex >= parceiros.length - 5 ? 0 : prevIndex + 1,
        );
      }
    }, 2000); // Intervalo de 2 segundos entre as transições

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, [currentIndex]);

  return (
    <section
      className={styles.parceiros}
      id="parceiros"
      aria-label="Nossos Parceiros"
      ref={myElementRef2}
    >
      {myElementRef2 ? (
        <ScrollTrigger
          onEnter={() => {
            myElementRef2.current.classList.add('animeLeft');
          }}
        />
      ) : (
        ''
      )}
      <h1 className={styles.titulo}>
        nossos parceiros<span className={styles.ponto}>.</span>
      </h1>

      <motion.div
        ref={myElementRef}
        className={styles.carouselContainer}
        whileHover={{ cursor: 'grab' }}
      >
        <motion.div
          className={styles.divItens}
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          whileTap={{ cursor: 'grabbing' }}
          whileHover={{ cursor: 'grab' }}
          animate={{
            x: `-${
              currentIndex *
              `${window.innerWidth <= 600 ? window.innerWidth / 2 : 280}`
            }}px`,
          }}
          transition={{ type: 'tween', duration: 0.5 }}
        >
          {parceiros.map((img, index) => (
            <motion.a
              ref={card}
              className={styles.link}
              href={img.url}
              key={index}
              target="_blank"
              whileTap={{ cursor: 'grabbing' }}
              whileHover={{ cursor: 'grab' }}
            >
              <img src={img.img} alt={`logo do parceiro`} />
            </motion.a>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Parceiros;
