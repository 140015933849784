import React from 'react';
import { useAvisoContext } from '../../UserContext';
import io from 'socket.io-client';
import styles from './Aviso.module.css';
import Input from '../Forms/Input';
import useForm from '../../Hooks/useForm';
import Modal from 'react-modal';
import Head from '../Helper/Head';

// const socket = io('https://konsist.com.br');

const Aviso = () => {
  const { logado, valorCompartilhado, setValorCompartilhado } =
    useAvisoContext();
  const [ativo, setAtivo] = React.useState(false);
  const [naoAtivo, setNaoAtivo] = React.useState(false);
  const [aviso, setAviso] = React.useState('');
  const [titulo, setTitulo] = React.useState('');
  const [corDeFundo, setCorDeFundo] = React.useState('');
  const [corTitulo, setCorTitulo] = React.useState('');
  const [corLetra, setCorLetra] = React.useState('');
  const [ativarAviso, setAtivarAviso] = React.useState(false);
  const [res, setRes] = React.useState(false);
  const [previa, setPrevia] = React.useState(false);

  function handleAviso(event) {
    setAviso(event.target.value);
  }
  function handleCorFundo(event) {
    setCorDeFundo(event.target.value);
  }
  function handleCorTitulo(event) {
    setCorTitulo(event.target.value);
  }
  function handleCorLetra(event) {
    setCorLetra(event.target.value);
  }
  function handleTitulo(event) {
    setTitulo(event.target.value);
  }

  function handleAtivar() {
    const check = document.querySelector('#ativar');
    setAtivarAviso(check.checked);
    setAtivo(!ativo);
  }

  const handleEnviar = async (e) => {
    e.preventDefault();
    const response = await fetch('https://konsist.com.br/atualizar-variavel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ativo: ativarAviso,
        titulo: titulo,
        aviso: aviso,
        corFundo: corDeFundo,
        corTitulo: corTitulo,
        corAviso: corLetra,
      }),
    });
    const json = response.json();
    if (response.status === 200) {
      setRes(true);
      console.log('deu certo!');
    }
  };

  // React.useEffect(() => {
  //   // Manipulador para receber atualizações do servidor WebSocket
  //   socket.on('atualizacao', (data) => {
  //     setValorCompartilhado(data.valor);
  //   });

  //   // Manipulador para receber o valor inicial do servidor Socket.IO
  //   socket.on('inicializacao', (data) => {
  //     setValorCompartilhado(data.valor);
  //   });

  //   // Cleanup da conexão ao desmontar o componente
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  async function lerAviso() {
    const response = await fetch('https://konsist.com.br/ver-aviso', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    console.log(json);
    setTitulo(json.titulo);
    setCorDeFundo(json.corFundo);
    setCorTitulo(json.corTitulo);
    setCorLetra(json.corAviso);
    setAviso(json.aviso);
    if (json.ativo) setAtivo(true);
    else setNaoAtivo(true);
  }

  React.useEffect(() => {
    lerAviso();
  }, []);

  return (
    <>
      <Head title="Aviso" description="Home do site da empresa Konsist" />
      {logado && (
        <section className={styles.containerForm}>
          <form className={styles.form} onSubmit={handleEnviar}>
            <Modal
              className={styles.previa}
              isOpen={previa}
              style={{ content: { background: corDeFundo } }}
              // onRequestClose={() => setAviso(false)}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
            >
              <h2 style={{ color: corTitulo }}>{titulo}</h2>
              <p style={{ color: corLetra }}>{aviso}</p>
              <button onClick={() => setPrevia(false)}>ciente</button>
            </Modal>
            <Modal
              className={styles.modal}
              isOpen={res}
              onRequestClose={() => setRes(false)}
              ariaHideApp={false}
            >
              <h2>
                Aviso alterado<span>!</span>
              </h2>
              {/* <p>
            Entratemos em contato com você <span>em breve.</span>
          </p> */}
              <button onClick={() => setRes(false)}>Fechar</button>
            </Modal>
            <div className={styles.checkbox}>
              {ativo ? (
                <input
                  id="ativar"
                  type="checkbox"
                  onChange={handleAtivar}
                  checked
                />
              ) : (
                <input
                  id="ativar"
                  type="checkbox"
                  onChange={() => setAtivarAviso(!ativarAviso)}
                />
              )}
              <label htmlFor="ativar">Ativar aviso</label>
            </div>
            <Input
              placeholder="Digite o título do aviso..."
              label="Título do aviso"
              type="text"
              name="titulo"
              onChange={handleTitulo}
              value={titulo}
            />

            <div className={styles.aviso}>
              <label htmlFor="aviso">Texto do aviso</label>
              <textarea
                id="aviso"
                placeholder="Digite o aviso aqui..."
                onChange={handleAviso}
                value={aviso}
              />
            </div>
            <Input
              placeholder="Digite a cor em qualquer padrão CSS"
              label="Cor de Fundo"
              type="text"
              name="fundo"
              onChange={handleCorFundo}
              value={corDeFundo}
            />
            <Input
              placeholder="Digite a cor em qualquer padrão CSS"
              label="Cor do Título"
              type="text"
              name="corTitulo"
              onChange={handleCorTitulo}
              value={corTitulo}
            />
            <Input
              placeholder="Digite a cor em qualquer padrão CSS"
              label="Cor do Aviso"
              type="text"
              name="corAviso"
              onChange={handleCorLetra}
              value={corLetra}
            />
            <button className={styles.enviar}>Enviar</button>
          </form>

          <button onClick={() => setPrevia(true)}>Prévia</button>
        </section>
      )}
    </>
  );
};

export default Aviso;
