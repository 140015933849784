import React from "react";
import styles from "./Local.module.css";
import mapa from "../../assets/mapa.jpg";
import horario from "../../assets/horario.svg";
import { Link } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_KEY, ID_MAP_KEY } from "../../config";

const Local = () => {
  const position = { lat: -15.732891191966011, lng: -47.89704157351034 };
  const myElementRef = React.useRef(null);

  return (
    <>
      <section ref={myElementRef} className={styles.local}>
        {myElementRef ? (
          <ScrollTrigger
            onEnter={() => {
              myElementRef.current.classList.add("animeLeft");
            }}
          />
        ) : (
          ""
        )}
        <div className={styles.titulo}>
          <h1>
            nossa localização<span>.</span>
          </h1>
        </div>
        <div className={styles.localizacao}>
          <APIProvider apiKey={GOOGLE_MAPS_KEY}>
            <div className={styles.mapa}>
              <Map zoom={15} center={position} mapId={ID_MAP_KEY}>
                <AdvancedMarker position={position}>
                  <Pin />
                </AdvancedMarker>
              </Map>
            </div>
          </APIProvider>
          <div className={styles.dados}>
            <div className={styles.cidade}>
              <span></span>
              <h2>Brasília</h2>
            </div>
            <div className={styles.texto}>
              <p>
                STN Conjunto M Entrada B Sala 213 <br /> Centro Clínico Vital
                Brazil - Asa Norte <br /> Brasília - DF <br /> <br />{" "}
                konsist@konsist.com.br <br />
                (61) 3321-3833 <br /> (61) 98592-7845 *somente Whatsapp
              </p>
            </div>
            <p>
              <img src={horario} alt="marcador de horário de funcionamento" />
              08-18h de seg à sex
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Local;
