import React, { useEffect, useState } from 'react'
import styles from './NavAreaCliente.module.css'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Sair } from "../../assets/sair.svg";
import path from 'path';
import { useUserContext } from '../../UserContextLogin';

const NavAreaCliente = () => {
  const location = useLocation();
  const { pathname } = location;
  const { userLogout } = useUserContext()

  function handleSair() {
    userLogout()
  }

  return (
    <nav className={styles.menu}>
      <div className={styles.divTitulo}>
        <h1>Bem-vindo</h1>
        <h1><span>CLIENTE</span></h1>
        <span className={styles.sep}></span>
      </div>
      <div className={styles.divMenu}>
        <div className={styles.divItem}>
          <span className={styles.marc}></span>
          <Link to='desbloqueio' className={pathname.includes('desbloqueio') ? styles.selected : styles.itemMenu}>
            Desbloqueio
          </Link>
        </div>
        <div className={styles.divItem}>
          <span className={styles.marc}></span>
          <Link to='pagamento' className={pathname.includes('pagamento') ? styles.selected : styles.itemMenu}>
            Pagamento
          </Link>
        </div>
        <div className={styles.divItem}>
          <span className={styles.marc}></span>
          <Link to='alterar-senha' className={pathname.includes('alterar-senha') ? styles.selected : styles.itemMenu}>
            Alterar senha
          </Link>
        </div>
        <div className={styles.divItem}>
          <span className={styles.marc}></span>
          <Link to='manuais' className={pathname.includes('manuais') ? styles.selected: styles.itemMenu}>Manuais</Link>
        </div>
        <div className={styles.divItem}>
          <span className={styles.marc}></span>
          <Link to='links' className={pathname.includes('links') ? styles.selected: styles.itemMenu}>Links</Link>
        </div>
      </div>
      <div className={styles.sair} onClick={handleSair}>
        <Sair className={styles.svg} />
        <p>Sair</p>
      </div>
    </nav>
  )
}

export default NavAreaCliente