import ibed from '../../assets/Clientes/ibed.svg';
import cotp from '../../assets/Clientes/cotp.svg';
import acb from '../../assets/Clientes/acb.svg';
import medcei from '../../assets/Clientes/medcei.svg';
import neuromaster from '../../assets/Clientes/neuromaster.svg';
import prodigest from '../../assets/Clientes/prodigest.svg';
import inmed from '../../assets/Clientes/inmed.svg';
import inderma from '../../assets/Clientes/inderma.svg';
import hospitalurologico from '../../assets/Clientes/hospitalurologico.svg';
import gastroscope from '../../assets/Clientes/gastroscope.svg';
import geroclinica from '../../assets/Clientes/geroclinica.svg';
import unineuro from '../../assets/Clientes/unineuro.svg';
import ibo from '../../assets/Clientes/ibo.svg';
import iped from '../../assets/Clientes/iped.svg';
import redecor from '../../assets/Clientes/redecor.svg';
import olhar from '../../assets/Clientes/olhar.svg';
import amai from '../../assets/Clientes/amai.svg';
import anamaia from '../../assets/Clientes/anamaia.svg';
import asmepro from '../../assets/Clientes/asmepro.svg';
import ceol from '../../assets/Clientes/ceol.svg';
import ceuta from '../../assets/Clientes/ceuta.svg';
import coopanest from '../../assets/Clientes/coopanest.svg';
import clinicaDaMulher from '../../assets/Clientes/Clinica-da-Mulher-1.png';
import bonifacio from '../../assets/Clientes/bonifacio.svg';

const Clientes = [
  {
    url: 'https://acb.med.br/',
    media: acb,
    nome: 'Associação do Corpo Clínica de Brasília',
  },
  {
    url: 'https://clinicaanamaia.com.br/',
    media: anamaia,
    nome: 'Clínica Ana Maia',
  },
  {
    url: 'http://amaibsb.com.br/',
    media: amai,
    nome: 'AMAI',
  },
  {
    url: 'https://www.asmepro.com.br/',
    media: asmepro,
    nome: 'ASMEPRO',
  },
  {
    url: 'https://www.ceuta.com.br/',
    media: ceuta,
    nome: 'Clínica Ceuta',
  },
  {
    url: 'https://centrootorrinodf.com.br/',
    media: ceol,
    nome: 'CEOL',
  },
  {
    url: 'https://clinicabonifacio.com.br/',
    media: bonifacio,
    nome: 'Clínica Bonifácio',
  },
  {
    url: 'http://coopanestdf.com.br/',
    media: coopanest,
    nome: 'Coopanest DF',
  },
  {
    url: 'https://www.cotp.com.br/',
    media: cotp,
    nome: 'Clínica de Olhos Teixeira Pinto',
  },
  {
    url: 'http://geroclinica.com.br/',
    media: geroclinica,
    nome: 'Geroclínica',
  },
  {
    url: 'https://hubrasilia.com.br/',
    media: hospitalurologico,
    nome: 'Hospital Urológico de Brasília',
  },
  {
    url: 'https://www.clinicaibed.com.br/site/',
    media: ibed,
    nome: 'Clínica IBED',
  },
  {
    url: 'https://iborl.com.br/',
    media: ibo,
    nome: 'Instituto Brasiliense de Otorrino',
  },
  {
    url: 'https://clinicadamulherdf.com.br/',
    media: clinicaDaMulher,
    nome: 'Clinica da Mulher',
  },
  {
    url: 'https://indermadf.com.br/',
    media: inderma,
    nome: 'Inderma',
  },
  {
    url: 'https://inmedonline.com.br/',
    media: inmed,
    nome: 'Instituto de Medicina e Diagnóstico',
  },
  {
    url: 'https://ipeddf.com.br/',
    media: iped,
    nome: 'IPED',
  },
  {
    url: 'https://medcei.com.br/',
    media: medcei,
    nome: 'MEDCEI - Centro Clínica e Diagnóstico',
  },
  {
    url: 'https://www.instagram.com/clinicagastroscope/',
    media: gastroscope,
    nome: 'Clínica Gastroscope',
  },
  {
    url: 'https://neuromaster.com.br/',
    media: neuromaster,
    nome: 'Neuromaster',
  },
  {
    url: 'https://grupoolhar.com.br/',
    media: olhar,
    nome: 'Olhar Prime',
  },
  {
    url: 'https://prodigest.com.br/',
    media: prodigest,
    nome: 'PróDigest',
  },
  {
    url: 'https://redecorcardiologia.com.br/',
    media: redecor,
    nome: 'Redecor',
  },
  {
    url: 'https://unineuro.com/',
    media: unineuro,
    nome: 'Unineuro',
  },
];

export default Clientes;
