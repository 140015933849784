import React, { createContext, useState, useContext } from "react";

interface ValorCompartilhado{
  ativo?: boolean;
  titulo?: string;
  aviso?: string;
  corFundo?: string;
  corTitulo?: string;
  corAviso?: string;
}

interface Aviso {
  aviso: boolean | undefined;
  abreAviso: boolean;
  user: string;
  senha: string;
  logado: boolean;
  valorCompartilhado: ValorCompartilhado;
  setValorCompartilhado: ( value: ValorCompartilhado) => void;
  setLogado: (value: boolean) => void;
  setAbreAviso: (value: boolean) => void;
  setAviso: (value: boolean | any) => void;
}

interface AvisoProviderProps {
  children: React.ReactNode;
}

const AvisoContext = createContext<Aviso>(
  {} as Aviso
);

export const useAvisoContext = () => useContext(AvisoContext);

export const AvisoProvider = ({ children }: AvisoProviderProps) => {
  let [aviso, setAviso] = useState(false);
  let [abreAviso, setAbreAviso] = useState(false);
  let [logado, setLogado] = useState(false);
  let [valorCompartilhado, setValorCompartilhado] = useState<ValorCompartilhado>({})
  const user = 'konsist';
  const senha = 'juizladrao_1994';
  const [data, setData] = useState(null);
  const [login, setLogin] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);




  return (
    <AvisoContext.Provider value={{ aviso, setAviso, abreAviso, setAbreAviso, user, senha, logado,  setLogado, valorCompartilhado, setValorCompartilhado}}>
      {children}
    </AvisoContext.Provider>
  );
};
