import React, { useState } from 'react';
import styles from './AlterarSenha.module.css';
import Input from '../Forms/Input';
import Modal from 'react-modal';
import { ALTERA_SENHA } from '../../ApiCliente';
import { useUserContext } from '../../UserContextLogin';
import useForm from '../../Hooks/useForm';

const AlterarSenha = () => {
  const { error, setError, loading, setLoading, token } = useUserContext();
  const [mensagem, setMensagem] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [repasswordError, setRepasswordError] = useState('');
  const [alterado, setAlterado] = useState(null);
  const password = useForm('');
  const repassword = useForm('');

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setPasswordError(null);
      if (password.value === repassword.value) {
        if (password.value.length > 5) {
          setLoading(true);
          const { url, options } = ALTERA_SENHA(token, {
            password: password.value,
          });
          const response = await fetch(url, options);
          const { message } = await response.json();
          setMensagem(message);
          if (!response.ok) throw new Error(message);
          setAlterado(true);
          password.setValue('');
          repassword.setValue('');
        } else {
          setPasswordError('A senha deve possuir no mínimo 6 caracteres');
        }
      } else {
        setPasswordError('As senhas não conferem');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={alterado}
        onRequestClose={() => setAlterado(false)}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
      >
        <p>{mensagem}</p>
        <button onClick={() => setAlterado(false)}>Fechar</button>
      </Modal>
      <div className={`${styles.divForm} animeLeft`}>
        <h3>Alterar senha dos serviços online</h3>
        <form className={`${styles.form} animeLeft`} onSubmit={handleSubmit}>
          <Input
            label="Nova senha"
            type="password"
            name="senha"
            placeholder="Digite a nova senha aqui..."
            classe="animeLeft"
            maxLength={25}
            value={password.value}
            disabled={loading}
            error={passwordError}
            {...password}
          />
          <div>
            <Input
              label="Confirmar nova senha"
              type="password"
              name="senha2"
              placeholder="Digite novamente a nova senha..."
              classe="animeLeft"
              id=""
              maxLength={25}
              disabled={loading}
              error={repasswordError}
              {...repassword}
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Alterando...' : 'Alterar senha'}
          </button>
          <p className={styles.error}>{passwordError}</p>
        </form>
      </div>
    </section>
  );
};

export default AlterarSenha;
