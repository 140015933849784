import React from 'react';
import useForm from '../../Hooks/useForm';
import Input from '../Forms/Input';
import styles from './LoginAviso.module.css';
import { Route, Routes, useNavigate } from 'react-router';
import Aviso from './Aviso';
import NotFound from '../NotFound';
import { useAvisoContext } from '../../UserContext';

const LoginAviso = () => {
  const { user, senha, setLogado } = useAvisoContext();
  const usuario = useForm('');
  const senhaInput = useForm('');
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    if (usuario.value === user && senhaInput.value === senha) {
      setLogado(true);
      navigate('/alteraraviso/aviso');
    }
  }
  return (
    <div className={styles.containerLogar}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          placeholder="Usuário"
          label="Usuário"
          type="text"
          name="usuario"
          {...usuario}
        />
        <Input
          placeholder="********"
          label="Senha"
          type="password"
          name="senha"
          {...senhaInput}
        />
        <button className={styles.button}>Entrar</button>
      </form>
    </div>
  );
};

export default LoginAviso;
