import React, { useState } from 'react'
import styles from './LoginForm.module.css'
import Input from '../Forms/Input'
import useForm from '../../Hooks/useForm'
import { Link, useNavigate } from 'react-router-dom'
import LoginPasswordLost from './LoginPasswordLost'
import { TOKEN_VALIDATE, USER_LOGIN } from '../../ApiCliente'
import { useUserContext } from '../../UserContextLogin'
import Modal from 'react-modal';


const LoginForm = () => {
  const { loading } = useUserContext();
  const username = useForm('cpf/cnpj');
  const senha = useForm('');
  const navigate = useNavigate();
  const [verificado, setVerificado] = useState(false);

  const { userLogin, error } = useUserContext();

  async function handleSubmit(e:any) {
    e.preventDefault();
  
    if (username.validate() && senha.validate()) {
      userLogin(username.value.replace(/\D/g, ''), senha.value);
    }
    
  }

  return (
    <section className={styles.containerMain}>
      <div className={`${styles.divForm} animeLeft`}>
        <h3>Login Serviços Online</h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input label='CPF/CNPJ' type='text' name='username' placeholder="00.000.000/0000-00" classe="" maxLength={18} {...username} disabled={false}  />
          <Input label='SENHA' type='password' name='senha' placeholder="********" classe="" maxLength={18} {...senha} disabled={false} />
          <Link className={styles.esqueceu} to="/login/esqueceu">Esqueceu sua senha?</Link>
          <button type='submit' disabled={loading}>{loading ? 'entrando...' : 'entrar'}</button>
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default LoginForm
