import React from "react";
import { Link } from "react-router-dom";
import styles from "./Clientes.module.css";
import Titulo from "../Helper/Titulo";
import ClientesLista from "./ListaClientes";
import Head from "../Helper/Head";

const Clientes = () => {
  const myElementRef = React.useRef(null);

  return (
    <>
      <Head title="Clientes" description="Alguns dos clientes Konsist" />
      <Titulo
        subTitulo="ALGUMAS DAS EMPRESAS QUE CONFIAM NOS NOSSOS SERVIÇOS"
        titulo="nossos clientes"
      />
      <section
        ref={myElementRef}
        className={`${styles.clientes} animeLeft`}
        id="clientes"
        aria-label="Nossos clientes"
      >
        {/* {myElementRef ? (
        <ScrollTrigger
          onEnter={() => {
            myElementRef.current.classList.add("animeLeft");
          }}
        />
      ) : (
        ""
      )} */}
        {/* <h1 className={styles.titulo}>
          alguns clientes<span className={styles.ponto}>.</span>
        </h1> */}

        <ul>
          {ClientesLista.map(({ url, nome, media }, index) => (
            <li key={index}>
              <Link className={styles.link} to={url} target="_blank">
                <img src={media} alt={nome} />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Clientes;
