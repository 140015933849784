import React from 'react';
import { useAvisoContext } from '../../UserContext';
import useForm from '../../Hooks/useForm';
import Input from '../Forms/Input';
import styles from './AlterarAviso.module.css';
import { Route, Routes, useNavigate } from 'react-router';
import Aviso from './Aviso';
import NotFound from '../NotFound';
import LoginAviso from './LoginAviso';

const AlterarAviso = () => {
  return (
    <section className={styles.container}>
      <Routes>
        <Route path="/" element={<LoginAviso />} />
        <Route path="/aviso" element={<Aviso />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </section>
  );
};

export default AlterarAviso;
