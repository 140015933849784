import React from "react";
import styles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import logo from "../assets/logo.png";

const Header = () => {
  const abrirPaginaPHP = () => {
    window.location.href = "./servicosonline/principal.php";
  };
  const [currentRoute, setCurrentRoute] = React.useState("");
  const [setor, setSetor] = React.useState("");
  const location = useLocation();
  const menuMobile = document.getElementById(
    "checkbox-menu"
  ) as HTMLInputElement;

  React.useEffect(() => {
    setCurrentRoute(location.pathname);
    if (setor === "parceiros") {
      scrollToElement(setor);
    }
    if (menuMobile) menuMobile.checked = false;
  }, [location, setor]);

  function scrollToElement(id: string) {
    if (location.pathname !== "/") setSetor(id);
    else if (location.pathname === "/") {
      setSetor("");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <header className={styles.header} id="header">
      <div className={styles.nav}>
        <Link className={styles.logo} to="/" aria-label="Konsist - Home">
          <img src={logo} alt="logo da empresa" />
        </Link>
        <input
          type="checkbox"
          className={styles.checkboxMenu}
          id="checkbox-menu"
        />
        <label className={styles.menuButtonContainer} htmlFor="checkbox-menu">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <nav className={styles.menu}>
          <div className={styles.itensMenu}>
            <Link
              className={
                currentRoute.includes("/produtos")
                  ? styles.selected
                  : styles.item
              }
              to="/produtos"
            >
              Produtos
            </Link>
            <span className={styles.sep}></span>
            <Link
              to="/clientes"
              className={
                currentRoute === "/clientes" ? styles.selected : styles.item
              }
            >
              Clientes
            </Link>
            <span className={styles.sep}></span>

            <Link
              className={
                currentRoute === "/sobre" ? styles.selected : styles.item
              }
              to="/sobre"
            >
              Sobre
            </Link>
            <span className={styles.sep}></span>
            <Link
              className={
                currentRoute === "/contato" ? styles.selected : styles.item
              }
              to="/contato"
            >
              Contato
            </Link>
            {window.innerWidth < 600 ? (
              <Link
                className={styles.item}
                to="/login"
              >
                Área do Cliente
              </Link>
            ) : (
              ""
            )}
          </div>
        </nav>
        <div className={styles.botoes}>
          <Link
            className={styles.linkButton}
            to="/login"
          >
            <button className={styles.button} id="cliente">
              ÁREA DO CLIENTE
            </button>
          </Link>
          <Link
            className={styles.linkButton}
            to="https://aplicativos-konsist.s3.sa-east-1.amazonaws.com/TeamViewerQSKonsist.exe"
          >
            <button className={styles.button} id="tw">
              BAIXAR ACESSO REMOTO
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
