import React from 'react';

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Preencha um e-mail válido',
  },
  number: {
    regex: /^\d+$/,
    message: 'Utilize apenas números',
  },
  telefone: {
    regex: /\([1-9]\d\)\s9?\d{4}-\d{4}$/,
    message: 'Digite um telefone válido',
  },
  cnpj: {
    validate: (cnpjParam) => {
      const cnpj = cnpjParam.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
    message: 'CPF/CNPJ inválido',
  },
  cpf: {
    validate: (cpfParam) => {
      const validateCPF = (cpfParam) => {
        const cpf = cpfParam.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

        if (cpf == '') return false; // Verifica se o CPF está vazio

        // Elimina CPFs inválidos conhecidos
        if (
          cpf == '00000000000' ||
          cpf == '11111111111' ||
          cpf == '22222222222' ||
          cpf == '33333333333' ||
          cpf == '44444444444' ||
          cpf == '55555555555' ||
          cpf == '66666666666' ||
          cpf == '77777777777' ||
          cpf == '88888888888' ||
          cpf == '99999999999'
        )
          return false;

        // Valida 1º dígito verificador
        let add = 0;
        for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) rev = 0;
        if (rev != parseInt(cpf.charAt(9))) return false;

        // Valida 2º dígito verificador
        add = 0;
        for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) rev = 0;
        if (rev != parseInt(cpf.charAt(10))) return false;

        return true; // CPF válido
      };
    },
    message: 'CPF/CNPJ inválido',
  },
  codDes: {
    regex: /^\d{5}-\d{5}-\d{5}-\d{5}$/,
    message: 'Digite um código de desbloqueio válido',
  },
  senha: {
    regex: /^\d{5}-\d{5}-\d{5}-\d{5}-\d{5}$/,
    message: '',
  },
};

const useForm = (type) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(null);

  function validate(value) {
    if (!type) return true;
    if (value.length === 0) {
      setError('Preencha um valor.');
      return false;
    } else if (
      types[type] &&
      types[type].regex &&
      !types[type].regex?.test(value)
    ) {
      setError(types[type].message);
    } else if (
      type === 'cpf' &&
      types.cpf.validate &&
      !types.cpf.validate(value)
    ) {
      setError(types['cpf'].message);
    } else if (
      type === 'cnpj' &&
      types.cnpj.validate &&
      types.cnpj.validate(value) === false
    ) {
      setError(types[type].message);
    } else {
      setError(null);
      return true;
    }
  }

  function onChange({ target }) {
    if (type === 'email' || type === 'number' || type === '') {
      if (error) validate(target.value);
      setValue(target.value);
    } else if (type === 'telefone') {
      function formatarTelefone(valor) {
        valor = valor.replace(/\D/g, '');
        valor = valor.replace(/^([1-9]\d)/, '($1) ');
        valor = valor.replace(/(\s9?\d{4})(\d{4})$/, '$1-$2');

        return valor;
      }
      setValue(formatarTelefone(target.value));
    } else if (type === 'codDes') {
      if (error) validate(target.value);
      function formatarCodDes(valor) {
        valor = valor.replace(/\D/g, '');
        valor = valor.replace(
          /(\d{5})(\d{1,5})?(\d{1,5})?(\d{1,5})?$/,
          (_, p1, p2, p3, p4) => {
            const parts = [p1, p2, p3, p4].filter(Boolean);
            return parts.join('-');
          },
        );
        return valor;
      }
      setValue(formatarCodDes(target.value));
    } else if (type === 'cnpj') {
      const formatarCNPJ = (cnpj) => {
        cnpj = cnpj.replace(/\D/g, '');
        return cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        );
      };
      setValue(formatarCNPJ(target.value));
    } else if (type === 'cpf') {
      const formatarCPF = (cpf) => {
        cpf = cpf.replace(/\D/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      };
      setValue(formatarCPF(target.value));
    } else if (type === 'cpf/cnpj') {
      const formatarCPF_CNPJ = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length <= 11) {
          return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
          return value.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            '$1.$2.$3/$4-$5',
          );
        }
      };
      setValue(formatarCPF_CNPJ(target.value));
    }
  }

  return {
    value,
    setValue,
    onChange,
    error,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
